<!-- template for the modal component -->
<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container relative">
          <!-- <button class="modal-default-button absolute top-0 right-0 p-4" v-on:click="$emit('close')">
            <font-awesome-icon :icon="faTimes"
            class="pointer-events-none flex items-center text-gray-800 text-2xl">
            </font-awesome-icon>
          </button> -->
          <div class="modal-header text-gray-800 text-2xl font-semibold py-4 text-center">
            <slot name="header">
              License Code
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <input type="text" v-model="licenseToken" class="appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4" v-bind:class="{ 'border-red-600': invalidLicense || activeLicense }" placeholder="License Token" required>
              <span class="text-red-600 text-base pt-2 block" v-if="invalidLicense">Invalid License</span>
              <span class="text-red-600 text-base pt-2 block" v-if="activeLicense">The License code is already in use</span>
              <span class="text-red-600 text-base pt-2 block" v-if="dbError">Database Error. Please, contact the support.</span>
              <div class="pt-4 text-right">
                  <button @click="verifyLicense" class="bg-logo-red text-white font-medium hover:bg-teal-600 py-4 px-8 rounded">Verify</button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

export default {
  name: 'tokenModal',
  data: () => ({
    licenseToken: null,
    faTimes,
    invalidLicense: false,
    activeLicense: false,
    dbError: false
  }),
  methods: {
    verifyLicense () {
      axios.post('/license', {
        license: this.licenseToken
      })
        .then((response) => {
          if (response.data.status === 'success' && response.data.message === 'licеnse is ok') {
            Vue.$cookies.set('license', this.licenseToken, Infinity);
            this.licenseToken = '';
            this.$emit('close');
          } else if (response.data.status === 'error' && response.data.message === 'license is use now') {
            this.invalidLicense = false;     
            this.activeLicense = true;     
          } else {
            this.activeLicense = false;     
            this.invalidLicense = true;     
          }
        })
        .catch((error) => {
          this.dbError = true;
        })
    }
  },
  mounted() {
    
    
  }
}
</script>