<template>
<div class="md:bg-white container md:mx-4 md:mx-auto md:shadow-lg rounded md:mt-32 flex md:flex-row flex-col md:max-w-l lg:max-w-xl justify-between">
  <div class="w-full p-8">
    <form method="POST" :action="url">
      <input type="hidden" name="_token" :value="token">
      <input type="hidden" name="license_cookie" :value="cookie">
      <p class="py-4">
        <input id="email" aria-label="email" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-800" type="text" name="email" placeholder="E-mail" :value="oldEmail" autofocus>
        <span class="text-red-400 block pt-2" v-if="errors.email">{{ errors.email[0] }}</span>
      </p>
      <p class="py-4">
        <input id="password" aria-label="password" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-800" type="password" name="password" placeholder="Password">
      </p>
      <!-- <p class="py-4 text-gray-500">
        <label>
          <input type="checkbox" aria-label="remember me" name="remember">
          Remember Me
        </label>
      </p> -->
      <p class="py-4">
        <button type="submit" class="bg-logo-red hover:bg-teal-600 text-white text-xl font-medium py-2 px-4 rounded">LOGIN</button>
      </p>
      <a href="password/reset" class="no-underline text-logo-red text-sm">Forgot Your Password?</a>
    </form>
  </div>
  <tokenModal v-if="showModal" @close="closeModal"></tokenModal>
</div>
</template>

<script>
import tokenModal from './tokenModal'

export default {
  components: {tokenModal},
  data: () => ({
    token: Laravel.csrfToken,
    errors,
    oldEmail,
    url,
    showModal: true
  }),
  methods: {
    closeModal() {
      this.showModal = false;
    }
  },
  mounted() {
    let licenseCookie = Vue.$cookies.get('license')
    if (licenseCookie) {
      this.showModal = false;
    }
  }
}
</script>
