var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c("div", { staticClass: "modal-container relative" }, [
          _c(
            "div",
            {
              staticClass:
                "modal-header text-gray-800 text-2xl font-semibold py-4 text-center"
            },
            [
              _vm._t("header", [
                _vm._v("\n            License Code\n          ")
              ])
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _vm._t("body", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.licenseToken,
                      expression: "licenseToken"
                    }
                  ],
                  staticClass:
                    "appearance-none block w-full bg-gray-200 text-gray-800 border border-gray-200 rounded py-3 px-4",
                  class: {
                    "border-red-600": _vm.invalidLicense || _vm.activeLicense
                  },
                  attrs: {
                    type: "text",
                    placeholder: "License Token",
                    required: ""
                  },
                  domProps: { value: _vm.licenseToken },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.licenseToken = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _vm.invalidLicense
                  ? _c(
                      "span",
                      { staticClass: "text-red-600 text-base pt-2 block" },
                      [_vm._v("Invalid License")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeLicense
                  ? _c(
                      "span",
                      { staticClass: "text-red-600 text-base pt-2 block" },
                      [_vm._v("The License code is already in use")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.dbError
                  ? _c(
                      "span",
                      { staticClass: "text-red-600 text-base pt-2 block" },
                      [_vm._v("Database Error. Please, contact the support.")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "pt-4 text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "bg-logo-red text-white font-medium hover:bg-teal-600 py-4 px-8 rounded",
                      on: { click: _vm.verifyLicense }
                    },
                    [_vm._v("Verify")]
                  )
                ])
              ])
            ],
            2
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }