var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "md:bg-white container md:mx-4 md:mx-auto md:shadow-lg rounded md:mt-32 flex md:flex-row flex-col md:max-w-l lg:max-w-xl justify-between"
    },
    [
      _c("div", { staticClass: "w-full p-8" }, [
        _c("form", { attrs: { method: "POST", action: _vm.url } }, [
          _c("input", {
            attrs: { type: "hidden", name: "_token" },
            domProps: { value: _vm.token }
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "license_cookie" },
            domProps: { value: _vm.cookie }
          }),
          _vm._v(" "),
          _c("p", { staticClass: "py-4" }, [
            _c("input", {
              staticClass:
                "shadow appearance-none border rounded w-full py-2 px-3 text-gray-800",
              attrs: {
                id: "email",
                "aria-label": "email",
                type: "text",
                name: "email",
                placeholder: "E-mail",
                autofocus: ""
              },
              domProps: { value: _vm.oldEmail }
            }),
            _vm._v(" "),
            _vm.errors.email
              ? _c("span", { staticClass: "text-red-400 block pt-2" }, [
                  _vm._v(_vm._s(_vm.errors.email[0]))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "no-underline text-logo-red text-sm",
              attrs: { href: "password/reset" }
            },
            [_vm._v("Forgot Your Password?")]
          )
        ])
      ]),
      _vm._v(" "),
      _vm.showModal
        ? _c("tokenModal", { on: { close: _vm.closeModal } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "py-4" }, [
      _c("input", {
        staticClass:
          "shadow appearance-none border rounded w-full py-2 px-3 text-gray-800",
        attrs: {
          id: "password",
          "aria-label": "password",
          type: "password",
          name: "password",
          placeholder: "Password"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "py-4" }, [
      _c(
        "button",
        {
          staticClass:
            "bg-logo-red hover:bg-teal-600 text-white text-xl font-medium py-2 px-4 rounded",
          attrs: { type: "submit" }
        },
        [_vm._v("LOGIN")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }